<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>

    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="inscriptions"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-info"
              :to="{ name: 'inscription-assign-group',
                     params: { id: props.row.id} }"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-editt-icon`"
                icon="RepeatIcon"
                class="cursor-pointer mr-1"
                size="16"
              /></router-link>

            <b-tooltip
              title="Affecter groupe"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-editt-icon`"
            />
            <router-link
              class="text-warning"
              :to="{ name: 'inscription-edit',
                     params: { id: props.row.id} }"
            >
              <feather-icon
                :id="`invoice-row-${props.row.id}-edit-icon`"
                icon="EditIcon"
                class="cursor-pointer mr-1"
                size="16"
              /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
            <feather-icon
              :id="`invoice-row-${props.row.id}-ediit-icon`"
              icon="PrinterIcon"
              class="cursor-pointer mr-1 text-success"
              size="16"
              @click="generateReport(props.row)"
            />
            <b-tooltip
              title="Imprimer préinscription"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-ediit-icon`"
            />
            <feather-icon
              :id="`invoice-row-${props.row.id}-ediitt-icon`"
              icon="ListIcon"
              class="cursor-pointer mr-1 text-primary"
              size="16"
              @click="generateAttestation(props.row)"
            />
            <b-tooltip
              title="Imprimer l'attestation"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-ediitt-icon`"
            />
            <router-link
              class="text-info"
              :to="{ name: 'student-show',
                     params: { id: props.row.id} }"
            >  <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteGroup(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />
          </span>
          <vue-html2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Attestation"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
          >
            <section slot="pdf-content">
              <html
                lang="en"
                dir="ltr"
              >

                <body style="margin:1cm;background-color:white;color:black">

                  <div style="display:flex; justify-content:space-between ;align-items:center">
                    <div>
                      <img
                        src="/logo-mit.png"
                        alt="mit"
                        width="200px"
                        height="auto"
                      >
                    </div>
                    <div>
                      <h5
                        style="color: black;
                text-align: center;
                font-weight: bold"
                      >
                        Ecole polytechnique mediterraneenne  <br> privée de tunis
                      </h5>
                    </div>
                  </div>
                  <div>
                    <hr>
                    <h5
                      style="margin-top: 20px;
                        font-size: 20px;
                        line-height: 45px;
                        color: black;
                        text-align: center;
                        font-weight: bold"
                    > ATTESTATION DE PRE-INSCRIPTION
                    </h5>

                    <h5
                      style="font-size: 20px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
                    >Année Universitaire :
                    </h5>
                    <p style="font-size: 15px; margin-top: 30px; color:black">
                      Le Directeur de l'École Supérieure Méditerranéenne Privée des Sciences Informatiques, Économiques et de

                      Gestion de Nabeul soussigné, certifie que :

                    </p>
                    <p style="font-size: 18px; margin-top: 10px; text-align: center; font-weight: bold; ">
                      {{ currentStudent.student_username }}
                    </p>

                    <p style="font-size: 15px; margin-top: 10px;"> Titulaire de la C.I .N (Passeport pour les étrangers) n° : {{ currentStudent.student_cin }}
                    </p>
                    <p style="font-size: 15px; margin-top: 10px;"> Né le : {{ currentStudent.student_date }} à {{ currentStudent.student_place }}
                    </p><p style="font-size: 15px; margin-top: 10px;"> Est inscrit en :
                    </p>
                    <table
                      style="border: 1px solid black;   width: 100%;
                border-collapse: collapse;"
                    >
                      <tr>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Parcour
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Filiére
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          niveau
                        </th>
                      </tr>
                      <tr>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.parcour_name }}
                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.sector }}

                        </th>
                        <th
                          style="border: 1px solid black; padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          {{ currentStudent.level }}
                        </th>
                      </tr>
                    </table>
                    <p style="font-size: 15px; margin-top: 20px;"> En qualit&eacute; d'&eacute;tudiant qui b&eacute;n&eacute;ficiera d'une inscription r&eacute;guli&egrave;re pour l'ann&eacute;e universitaire {{ new Date().getFullYear() }}/{{ new Date().getFullYear()+1 }}.
                    </p>
                    <br>
                    <br>
                    <br>
                    <p style="font-size: 15px; margin-top: 10px;"> Cette attestation est d&eacute;livr&eacute;e &agrave; l'int&eacute;ress&eacute;e pour servir et valoir ce que de droit.
                    </p>
                    <br><br>

                    <div style="text-align: right; margin-right: 100px;">
                      <h6 style="margin-right: 10px; color:black;">
                        Le Directeur
                      </h6>
                      <h6 style="margin-right: 10px; color:black;">
                        Dr.MAALEJ Rabii
                      </h6>
                    </div>

                  </div>
                  <br><br><br><br>
                  <br><br><br><br><br>
                  <br><br>
                  <br><br>
                  <div class="footer-style"> N.B : il ne peut être délivré qu'une seule attestation. </div>
                </body>
              </html>
            </section>
          </vue-html2pdf>
          <vue-html2pdf
            ref="attestation"
            :show-layout="false"
            :float-layout="true"
            :enable-download="true"
            :preview-modal="true"
            :paginate-elements-by-height="1400"
            filename="Attestation"
            :pdf-quality="2"
            :manual-pagination="false"
            pdf-format="a4"
            @progress="onProgress($event)"
            @hasStartedGeneration="hasStartedGeneration()"
            @hasGenerated="hasGenerated($event)"
          >
            <section slot="pdf-content">
              <html
                lang="en"
                dir="ltr"
              >

                <body style="margin:1cm;background-color:white;color:black">

                  <div style="display:flex; justify-content:space-between ;align-items:center">
                    <div>
                      <img
                        src="/logo-mit.png"
                        alt="mit"
                        width="200px"
                        height="auto"
                      >
                    </div>
                    <div>
                      <h5
                        style="color: black;
                text-align: center;
                font-weight: bold"
                      >
                        Ecole polytechnique mediterraneenne  <br> privée de Nabeul
                      </h5>
                    </div>
                  </div>
                  <div>
                    <hr>
                    <h5
                      style="margin-top: 20px;
                        font-size: 20px;
                        line-height: 45px;
                        color: black;
                        text-align: center;
                        font-weight: bold"
                    > FICHE ETUDIANT
                    </h5>
                    <br><br>
                    <div style="text-align: right;">
                      <span
                        style="border: 1px solid black; padding: 50px; "
                      >photo</span>

                    </div>
                    <h5
                      style="font-size: 14px;
                line-height: 25px;
                color: black;
                text-align: center;
                font-weight: bold"
                    >ETAT CIVIL
                    </h5>

                    <br>
                    <br>
                    <table
                      style="  width: 100%;
                border-collapse: collapse;"
                    >
                      <tr>
                        <th
                          style=" padding: 2px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Nom
                        </th>
                        <td>{{ currentStudent.student_FirstName }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Prénom
                        </th>
                        <td>{{ currentStudent.student_lastName }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Titulaire CIN (passeport pour les étrangers)  N° :
                        </th>
                        <td>{{ currentStudent.student_cin }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Né le :
                        </th>
                        <td>{{ currentStudent.student_date }}  à  {{ currentStudent.student_place }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          INE N° :
                        </th>
                        <td>000122</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Nationalité  :
                        </th>
                        <td>{{ currentStudent.student_nationality }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
      vertical-align: top;
      font-size: 14px;"
                        >
                          Adresse  :
                        </th>
                        <td>{{ currentStudent.student_adress }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
                          vertical-align: top;
                          font-size: 14px;"
                        >
                          Code Postal :
                        </th>
                        <td>016666</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >
                          Adresse email :
                        </th>
                        <td>{{ currentStudent.student_email }}</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >
                          Téléphone  :
                        </th>
                        <td>785222333</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >
                          Baccalauréat   :
                        </th>
                        <td>Informatiques</td>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >Année d'obtention:</th>
                        <td>2016/2017</td>
                      </tr>
                      <tr>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >
                          Dernier diplôme obtenu:
                        </th>
                        <td>01/01/1995       à        GBADOLITE</td>
                        <th
                          style="  padding: 5px;
                            vertical-align: top;
                            font-size: 14px;"
                        >Année d'obtention:</th>
                        <td>  2015/2016</td>
                      </tr>

                    </table>
                    <br><br>
                    <p style="font-size: 15px; margin-top: 20px;">Inscription  {{ new Date().getFullYear() }}/{{ new Date().getFullYear()+1 }} : 1ère année Licence  en Gestion Parcours Tronc commun Gestion.
                    </p>

                    <p style="font-size: 15px; margin-top: 10px;"> Je déclare avoir reçu une copie du règlement intérieur et la charte des étudiants, l'avoir lus et acceptés.
                    </p>
                    <br><br>
                    <br><br>

                    <div style="text-align: left;">
                      <h6 style=" color:black;">
                        Nabeul, le 09/08/2022

                      </h6>
                    </div>
                    <div style="text-align: right; margin-right: 100px;">
                      <h6 style="margin-right: 10px; color:black;">
                        Signature de l'étudiant

                      </h6>
                    </div>

                  </div>
                </body>
              </html>
            </section>
          </vue-html2pdf>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormInput, BFormSelect,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BTooltip,
    VueHtml2pdf,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      user: {},
      columns: [
        {
          label: 'Identité',
          field: 'student.id',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Nom et prénom',
          field: 'student.username',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nom',
          },
        },
        {
          label: 'Email',
          field: 'student.email',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher email',
          },
        },

        {
          label: 'Date Inscription',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher type',
          },
        },
        {
          label: 'Filiére ',
          field: 'parcour.sector',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher',
          },
        },
        {
          label: 'Parcours',
          field: 'parcour.title',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher ',
          },
        },
        {
          label: 'Redoublant',
          field: 'redouble',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher ',
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      inscriptions: [],
      currentStudent: {},

      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      statuss: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getInscriptions()
    this.user = storeAuth.state.user
  },
  methods: {
    generateReport(student) {
      // console.log('id user', id)
      this.currentStudent = student
      this.$refs.html2Pdf.generatePdf()
    },
    generateAttestation(student) {
      // console.log('id user', id)
      this.currentStudent = student
      this.$refs.attestation.generatePdf()
    },
    async getInscriptions() {
      this.load = 'true'
      const response = await axios.get('/api/inscriptions/')
      this.inscriptions = response.data
      this.load = 'false'
    },
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      // const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )}`
    },

    deleteGroup(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/groups/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getGroups()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Groupe supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'Seances Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';

.footer-style {
  position: absolute;
  bottom: 0;
}

</style>
